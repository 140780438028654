import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ErrorBoundary from './../components/error-boundary';
import CommonPopup from './../components/common-popup';
import { baseLayoutRoutes } from '../routes';
import { updateUserCurrentClinic, setUserCurrentLocalClinic } from '../actions/user';
import { getDoctors, setDoctors } from '../actions/doctors';
import { getVitals, setVitals } from '../actions/rpm/vitals';
import SelectWithSearch from '../lib/forms/select-with-search';
import env from 'react-dotenv';
import CompanyLogo from './company-logo';
import {
    clearMonthlyReport
} from "../actions/reports";

class BaseLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisibleUserClinics: false,
            currentClinic: null
        };

        this.userLinksRef = React.createRef();

        this.toggleUserClinics = this.toggleUserClinics.bind(this);

        this.onContinueSession = this.onContinueSession.bind(this);
        this.onLogoutSession = this.onLogoutSession.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    componentDidMount() {
        this.preloadData();
        document.addEventListener('mousedown', this.handleWrapperClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleWrapperClick, false);
    }

    handleWrapperClick = (e) => {

        if (!this.node.contains(e.target) && this.state.isVisibleUserClinics) {

            if (e.target.className !== 'Select-clear') {

                this.setState({
                    isVisibleUserClinics: false
                });
            }
            
        }
    }

    componentDidUpdate(prevProps) {
        const oldUser = prevProps.user;
        const newUser = this.props.user;

        if (oldUser.currentClinic !== newUser.currentClinic) {

            this.preloadData();
        }

        if (!window.getCookie('UserToken')) {

            window.location.href = env.LOGIN_URL;
        }
    }

    preloadData() {

        const { dispatch, user } = this.props;

        getDoctors(user.currentClinic).then((res) => {

            dispatch(
                setDoctors(
                    res.data.doctors
                )
            );
        });

        getVitals(user.currentClinic).then((res) => {

            dispatch(
                setVitals(
                    res.data.vitals
                )
            );
        });
    }

    getActiveSections() {
        const { pathname } = this.props.history.location;

        return {
            isCCM: pathname.indexOf('/app/ccm') === 0,
            isRPM: pathname.indexOf('/app/rpm') === 0,
            isReports: pathname.indexOf('/app/reports') === 0
        };
    }

    getActiveSubSections(activeSections) {
        const { pathname } = this.props.history.location;

        if (activeSections.isCCM) {
            return {
                isTodo: pathname === '/app/ccm' || pathname.indexOf('/app/ccm/todo') === 0,
                isAddon: pathname.indexOf('/app/ccm/addon') === 0,
                isSearch: pathname.indexOf('/app/ccm/search') === 0
            };
        }

        if (activeSections.isRPM) {
            return {
                isDailyCarePlans: pathname === '/app/rpm' || pathname.indexOf('/app/rpm/daily-monitoring-plans') === 0,
                isCarePlans: pathname.indexOf('/app/rpm/monitoring-plans') === 0 || pathname.indexOf('/app/rpm/create-monitoring-plan') === 0
            };
        }

        if (activeSections.isReports) {
            return {
                isReports: pathname === '/app/reports',
                isExceptions: pathname.indexOf('/app/reports/exceptions') === 0,
                isPatients: pathname.indexOf('/app/reports/patients') === 0
            };
        }

        return {};
    }

    toggleUserClinics(e) {
        e.preventDefault();

        const { isVisibleUserClinics } = this.state;

        this.setState({
            isVisibleUserClinics: !isVisibleUserClinics
        });
    }

    onSelectUserClinic = (clinic) => {
        clinic = parseInt(clinic);

        if (isNaN(clinic)) {

            return null;
        }

        const { dispatch } = this.props;

        const clinicInfo = window.projectUser.clinics.filter(c => c.id == clinic);
        const idleTimer = clinicInfo[0].idleTimeOut;

        window.setCookies('idleTimer', idleTimer, 30);

        dispatch(
            updateUserCurrentClinic(clinic)
        );

        setUserCurrentLocalClinic(clinic);

        this.setState({
            isVisibleUserClinics: false
        });

        this.props.history.push('/app');
    }

    onContinueSession() {
        window.idleMonitor.reset();
    }

    onLogoutSession() {
        setUserCurrentLocalClinic(null);
        window.idleMonitor.logout();
    }

    onLogout() {
        setUserCurrentLocalClinic(null);
        window.eraseCookie('UserToken', env.COOKIE_DOMAIN);
    }

    renderMainSections() {
        const activeSections = this.getActiveSections();

        return (
            <nav className='main-sections'>
                <ul>
                    <li className={activeSections.isCCM ? 'active' : ''}><Link to='/app/ccm'>CCM</Link></li>
                    <li className={activeSections.isRPM ? 'active' : ''}><Link to='/app/rpm'>RPM</Link></li>
                    <li className={activeSections.isReports ? 'active' : ''}><Link to='/app/reports'>Reports</Link></li>
                </ul>
            </nav>
        );
    }

    renderSubSections() {
        const activeSections = this.getActiveSections();
        const activeSubSections = this.getActiveSubSections(activeSections);

        if (activeSections.isCCM) {
            return (
                <nav className='sub-sections'>
                    <ul>
                        <li className={activeSubSections.isTodo ? 'active' : ''}><Link to='/app/ccm'>ToDo</Link></li>
                        <li className={activeSubSections.isAddon ? 'active' : ''}><Link to='/app/ccm/addon'>Add-on</Link></li>
                        <li className={activeSubSections.isSearch ? 'active' : ''}><Link to='/app/ccm/search'>Search</Link></li>
                    </ul>
                </nav>
            );
        }

        if (activeSections.isRPM) {
            return (
                <nav className='sub-sections'>
                    <ul>
                        <li className={activeSubSections.isDailyCarePlans ? 'active' : ''}><Link to='/app/rpm'>Daily monitoring</Link></li>
                        <li className={activeSubSections.isCarePlans ? 'active' : ''}><Link to='/app/rpm/monitoring-plans'>Monitoring plans</Link></li>
                    </ul>
                </nav>
            );
        }

        if (activeSections.isReports) {
            return (
                <nav className='sub-sections'>
                    <ul>
                        <li className={activeSubSections.isReports ? 'active' : ''}><Link to='/app/reports?keep_selection=true'>Summary</Link></li>
                        {/*<li className={activeSubSections.isExceptions ? 'active' : ''}><Link to='/app/reports/exceptions'>Exceptions</Link></li>*/}
                        <li className={activeSubSections.isPatients ? 'active' : ''}><Link to='/app/reports/patients'>Patient History</Link></li>
                    </ul>
                </nav>
            );
        }

        return (
            <nav className='sub-sections'>
                <ul/>
            </nav>
        );
    }

    renderUser() {
        const { user } = this.props;
        const { isVisibleUserClinics } = this.state;

        if (!user) {
            return null;
        }

        let fullName = `${user.firstName} ${user.lastName}`;

        for (let i = 0; i < user.clinics.length; i += 1) {
            const clinic = user.clinics[i];

            if (user.currentClinic === clinic.id) {
                fullName += ` - ${clinic.name}`;
            }
        }

        let userClinics = null;

        if (isVisibleUserClinics) {
            const clinics = [];

            for (let i = 0; i < user.clinics.length; i += 1) {
                const clinic = user.clinics[i];

                clinics.push({
                    label: clinic.name,
                    value: clinic.id
                });
            }

            userClinics = (
                <div className='user-clinics'>
                    <div className='user-clinics-header'>Clinics</div>
                    <div className='user-clinics-list'>
                        <SelectWithSearch
                            name='clinic'
                            defaultValue={ user.currentClinic }
                            values={ clinics }
                            inline={ true }
                            onChange={clinic => this.onSelectUserClinic(clinic) }
                        />
                    </div>
                </div>
            );
        }

        return (
            <nav className='user'>
                <div className='nav-user-links' ref={ userLinksNode => this.node = userLinksNode }>
                    <ul className='user-links'>
                        <li>
                            <Link to='' onClick={this.toggleUserClinics}>
                                {fullName}
                            </Link>
                        </li>
                        <li>
                            <a href={env.LOGOUT_URL} onClick={this.onLogout}>
                                Logout
                            </a>
                        </li>
                    </ul>
                    {userClinics}
                </div>
            </nav>
        );
    }

    renderSessionBlocker() {
        const { user } = this.props

        if (!user.isBlockedSession) {
            return null;
        }

        return (
            <CommonPopup header='Message' onClose={this.onContinueSession}>
                <div className='session-blocker'>
                    <div className='session-blocker-message'>
                        You will be logged off in some minutes due to inactivity.
                    </div>
                    <div className='session-blocker-control'>
                        <div className='button'
                             onClick={this.onContinueSession}>
                            Continue session
                        </div>
                        <div className='button'
                             onClick={this.onLogoutSession}>
                            Logout
                        </div>
                    </div>
                </div>
            </CommonPopup>
        );
    }

    render() {
        const { user } = this.props;

        if (user.clinics.length === 0) {
            return (
                <div className='site-global-message'>
                    <div className='message'>
                        <div>User has no configured clinics</div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                {this.renderSessionBlocker()}

                <div className='site-header'>
                    <Link to='/app'>
                        <CompanyLogo />
                        {/*<img className='logo' src='/static/img/ecare21-logo.png' alt='logo' />*/}
                    </Link>

                    <div className='sections'>
                        {this.renderMainSections()}
                        {this.renderSubSections()}
                    </div>

                    {this.renderUser()}
                </div>

                <ErrorBoundary>
                    <div className='site-content'>
                        {baseLayoutRoutes}
                    </div>
                </ErrorBoundary>
            </div>
        );
    }
}

export default connect(state => {
    let user = null;

    if (state.user.userId) {
        user = state.user;
    }

    return {
        user
    };
})(BaseLayout);
