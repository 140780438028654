import React from 'react';
import { connect } from 'react-redux';
import { clearDailyCarePlanHistory, getDailyCarePlanHistory, setDailyCarePlanHistory } from '../../actions/rpm/care-plans';
import RequestLoader from './../../components/request-loader';
import PatientTooltip from './../../components/patient-tooltip';
import Form from './../../lib/forms/form';
import SelectWithLoading from './../../lib/forms/select-with-loading';
import { getPatientsByName } from './../../actions/patients';
import { getJournalPatient, setJournalPatient, clearJournalPatient } from './../../actions/ccm/patient-journal';
import { formatShortDate } from '../../lib/date';
import Lines from '../../components/lines';
import CommonPopup from '../../components/common-popup';
import "./show-patients.css";
import { removeReport } from '../../actions/ccm/todo';

class ReportsShowPatients extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            popupOpen: false,
            reportId: null,
            reasonToStrikeThrought: ''
        };

        this.loadPatientsByName = this.loadPatientsByName.bind(this);
        this.onPatientChange = this.onPatientChange.bind(this);
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(clearDailyCarePlanHistory());
        dispatch(clearJournalPatient());
    }

    goToPatient() {

        const { dispatch, clinicId } = this.props;
        const { id: patientId } = this.props.journalPatient;

        getDailyCarePlanHistory({ clinicId, patientId  }).then(({ data: { history } }) => {

            dispatch(
                setDailyCarePlanHistory(
                    history
                )
            );

            this.setState({
                historyLoaded: true
            });
        });
    }

    loadPatientsByName(name, callback) {
        const { clinicId } = this.props;

        getPatientsByName(clinicId, name).then((res) => {

            const patients = res.data.patients.map((patient) => {
                let dob = '';

                if (patient.dob !== null) {
                    dob = ', ' + patient.dob;
                }

                return {
                    value: patient.id,
                    label: patient.firstName + ' ' + patient.lastName + dob
                };
            });
            callback(patients);

        });
    }

    onPatientChange(patient) {

        const { dispatch, clinicId } = this.props;

        dispatch(clearDailyCarePlanHistory());

        if (!patient) {
            this.props.dispatch(clearJournalPatient());
            return;
        }

        this.setState({ isLoading: true, history: false });

        getJournalPatient(clinicId, patient).then((res) => {

            this.props.dispatch(setJournalPatient(res.data.patient));
            this.setState({ isLoading: false });
        });
    }

    renderSearch() {
        return (
            <Form onSubmit={() => {}} className='form'>
                <div className='form-horizontal-control'>
                    <SelectWithLoading
                        name='patient'
                        label='Search by patient'
                        onLoadMore={this.loadPatientsByName}
                        onChange={this.onPatientChange}
                        inline={true}
                        tooltip={<PatientTooltip />}
                    />
                </div>
            </Form>
        );
    }

    setReportId = reportId => {

        this.setState({
            reportId,
            popupOpen: true
        });
    }

    renderPopup = () => {

        if (!this.state.popupOpen) {

            return null;
        }

        return (
            <CommonPopup
                classNames={['mark-measurement-as-wrong-popup']}
                header='Enter reason to remove'
                onClose={() => { this.cancelStrikeThrough() }}>
                <textarea
                    className='form-textarea'
                    onChange={({ target: { value } }) => {
                        this.setState({
                            reasonToStrikeThrought: value
                        });
                    }}
                    value={this.state.reasonToStrikeThrought} />
                <div className='buttons'>
                    <button className='confirm' onClick={() => this.cancelStrikeThrough() }>Cancel</button>
                    <button className='cancel' onClick={() => this.confirmStrikeThrough() }>Remove</button>
                </div>
            </CommonPopup>
        );
    }

    confirmStrikeThrough = () => {

        const { reportId, reasonToStrikeThrought } = this.state;
        const { dispatch, clinicId } = this.props;

        this.setState({
            historyLoaded: false
        });

        dispatch(clearDailyCarePlanHistory());

        removeReport(clinicId, reportId, reasonToStrikeThrought).then(() => {

            this.goToPatient();
            this.cancelStrikeThrough()
        });
    }

    cancelStrikeThrough = () => {

        this.setState({
            reportId: null,
            popupOpen: false,
            reasonToStrikeThrought: null
        });
    }

    reasonToStrikeThroughtHandler = ({ target: { value } }) => {

        this.setState({
            reasonToStrikeThrought: value
        });
    }

    renderPatientHistory = () => {

        if (!this.props.isDailyCarePlanHistoryLoaded) {

            return null;
        }

        const { dailyCarePlanHistory, isDailyCarePlanHistoryLoaded } = this.props;

        return (
            <div className='rpm-history-wrap'>
                <div className='detailed-data'>
                    <table className='table rpm-history'>
                        <thead>
                            <tr>
                                <th>By</th>
                                <th>Patient</th>
                                <th>Action</th>
                                <th>Assigned</th>
                                <th>Description/Resolution</th>
                                <th>Created</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isDailyCarePlanHistoryLoaded
                                ? dailyCarePlanHistory.length
                                    ? (dailyCarePlanHistory.map((i, k) => {

                                        return (
                                            <tr key={k}>
                                                <td>
                                                    {`${i.createdByFirstName[0]}${i.createdByLastName[0]}`}
                                                </td>
                                                <td>
                                                    {`${i.patientFirstName} ${i.patientLastName}`}
                                                </td>
                                                <td>
                                                    {`${i.actionName}`}
                                                </td>
                                                <td>
                                                    {i.assignedToFirstName ? `${i.assignedToFirstName} ${i.assignedToLastName}` : ''}
                                                </td>
                                                <td>
                                                    {i.description ? <Lines data={`${i.description}`} /> : null}
                                                    {i.resolution ? <Lines data={`${i.resolution}`}/> : null}
                                                </td>
                                                <td>
                                                    {formatShortDate(i.createdAt)}
                                                </td>
                                                <td>
                                                    {i.actionName === 'Warning from RPM' || i.status !== 'DONE'
                                                        ? null
                                                        : i.removed
                                                            ? 'Removed'
                                                            : <button
                                                                className="strike-throught"
                                                                onClick={() => this.setReportId(i.id)} title="Remove">+</button>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    }))
                                    : <tr><td colSpan='7'>No data for display</td></tr>
                                : <tr><td colSpan='7'>Loading data...</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    renderPatientsTable() {
        const { isJournalPatientLoaded, journalPatient } = this.props;

        if (!isJournalPatientLoaded) {
            return null;
        }

        const rows = (
            <tr key={journalPatient.id} onClick={() => { this.goToPatient() }}>
                <td>{journalPatient.firstName}</td>
                <td>{journalPatient.lastName}</td>
                <td>{journalPatient.dob}</td>
            </tr>
        );

        return (
            <table className='table table-row-hover'>
                <thead>
                    <tr>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>DOB</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }

    render() {
        return (
            <>
                {this.renderPopup()}
                <div className='dashboard-patients'>
                    {this.renderSearch()}
                    {this.state.isLoading ? <RequestLoader center /> : null}
                    {this.renderPatientsTable()}
                    {this.renderPatientHistory()}
                </div>
            </>
        );
    }
}


const mapStateToProps = state => ({
    isPatientsLoaded: state.patients.isPatientsLoaded,
    patients: state.patients.patients,
    isJournalPatientLoaded: state.ccmPatientJournal.isJournalPatientLoaded,
    journalPatient: state.ccmPatientJournal.journalPatient,
    isDailyCarePlanHistoryLoaded: state.rpmCarePlans.isDailyCarePlanHistoryLoaded,
    dailyCarePlanHistory: state.rpmCarePlans.dailyCarePlanHistory,
    clinicId: state.user.currentClinic
});

export default connect(mapStateToProps)(ReportsShowPatients);